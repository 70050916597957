<template>
<h1 class="text-center">Forms</h1>
</template>

<script>
export default {
  name: "Forms"
}
</script>

<style scoped>

</style>